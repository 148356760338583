/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import client from "../client";
import { Menu } from "../menu";
import { BsFillPlayBtnFill } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from "react-icons/io";

export function Project() {
  const [movie, setMovie] = useState(null);
  const [videoOpen, setVideoOpen] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    client
      .fetch(`*[slug.current == '${id}']`)
      .then((data) => setMovie(data[0]))
      .catch(console.error);
  }, []);

  return (
    <div className="project-container">
      
      {movie ? (   
        <div>                  

          <Menu />
        
          <div className='project-descrition'>
            
            <div className='project-descrition-text'>
          
              <ul className='project-descrition-list'>
          
                <li style={{fontSize:26, fontWeight:700,lineHeight:'28px'}}>{movie.title }</li>
                { (movie.type)        ? (<li>{movie.type}</li>) : false }
                { (movie.media)       ? (<li>{movie.media}</li>) : false }
                <span style={{color:"#1b85ff"}}>{ (movie.isComingSoon)   ? ( "Coming soon") : false }</span>
                <li><br/></li>
                { (movie.dir)         ? (<li><span>Director</span> {movie.dir}</li>) : false }
                { (movie.dop)         ? (<li><span>Director of Photography</span> {movie.dop}</li>) : false }
                { (movie.productions) ? (<li><span>Production</span> {movie.productions}</li>) : false }
                { (movie.co_grader)   ? (<li><span>co-grader</span> {movie.co_grader}</li>) : false }
                { (movie.lenght)      ? (<li><span>Lenght</span> {movie.lenght}</li>) : false }
                { (movie.year)        ? (<li><span>Year</span> {movie.year}</li>) : false }
                <li><br/></li>
                { (movie.facility)    ? (<li><span>Graded at</span> {movie.facility}</li>) : false }
                { (movie.software)    ? (<li><span>on</span> {movie.software}</li>) : false }
              </ul>

              {(movie.video_aws) ?           
                <div className='project-video-box' onClick={()=>setVideoOpen(true)}>
                  <BsFillPlayBtnFill />
                </div>
              : null }              
            </div>
        
          </div>      

          {(movie.video_aws && videoOpen ) ? 
            <div className='project-video'>
              <div className='project-video-inner'>
                <div className="video-close" onClick={()=>setVideoOpen(false)}>
                  <IoMdCloseCircleOutline />
                </div>
                <video src={ movie.video_aws } width={'100%'} controls={true} autoPlay={true} /> 
              </div>
            </div>
          : null}
        
          <div className='project-items-container'>
        
            {movie.images.map((image, ind) => (
              <div key={ind} className='project-images'>
                <div className='grid-project'>
                  <div className='grid-project-inner-wrapper'>
                    <img src={image} />              
                  </div>
                </div>
              </div>
            ))}      
          
          </div>


      </div>     
        

      ) : null}
    </div>
  );
}
