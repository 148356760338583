/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import client from "../client";
import { Menu } from "../menu";
import { Link } from "react-router-dom"
import { BsFillPlayBtnFill } from 'react-icons/bs';

export function Home() {
  const [movies, setMovies] = useState(null);

  useEffect(() => {
    client
    .fetch(`*[_type == 'movie'] |  order(_createdAt desc) `)
      .then((data) => setMovies(data))
      .catch(console.error);
  }, []);

  return (
    <div>

      <Menu />
    

      <div className="portfolio-grid-overlay">
      { movies ?
        movies.map((movie, index) => (
            <div key={index} className="grid-item">
              <Link to={{pathname: `/${movie.slug.current}` }}>
              <div className="grid-image">
                <div className="grid-image-inner-wrapper">
                  <img src={movie.images[0]} />
                </div>
              </div>

              <div className='portfolio-text'>
                <h4 className='portfolio-title'>{ (movie.title) ? ( movie.title) : false }</h4>
                <h5 className='portfolio-title'>{ (movie.dir)   ? ( 'by ' + movie.dir) : false }
                  <br/><span className='portfolio-soon'>{ (movie.isComingSoon)   ? ( "Coming soon") : false }</span>
                </h5>
                
                <h1 className='portfolio-title'>{ (movie.video_aws) ? <BsFillPlayBtnFill /> : null }</h1>     
              </div> 
              </Link>
            </div>
          ))
        : null }
    </div>
  </div>
  );
}
